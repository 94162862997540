import { useState } from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useAuth, useQuery } from '../App'
import useStyles from '../styles/useStyles'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { InputAdornment } from '@mui/material'

const Reset = () => {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const auth = useAuth()
  const query = useQuery()
  const classes = useStyles()
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.rootlog}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            item
            style={{
              maxWidth: '512px',
              backdropFilter: 'blur(4px)'
            }}
            className={classes.gridContainer}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                className={classes.paddingBox}
              >
                <Grid item className={classes.titleBox}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box fontSize="caption.fontSize" textAlign="center">
                      {'Reset your password'}
                    </Box>

                    <Box
                      fontWeight="fontWeightBold"
                      fontSize="h6.fontSize"
                      textAlign="center"
                    >
                      {'Enter your new password'}
                    </Box>
                  </Grid>
                </Grid>
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    fullWidth
                    name="password"
                    type="password"
                    className={classes.textField}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="********"
                    label="New password"
                    InputProps={{
                      className: classes.inputField,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOpenIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    fullWidth
                    name="password"
                    label="Confirmation"
                    type="password"
                    className={classes.textField}
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    placeholder="********"
                    InputProps={{
                      className: classes.inputField,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOpenIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <Grid item className={classes.marginVerticalOnly}>
                    <Button
                      className={classes.buttonSquare}
                      fullWidth
                      size="large"
                      onClick={() =>
                        auth?.resetPass(query.get('oobCode'), password)
                      }
                      variant="contained"
                    >
                      Reset
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Reset
