import CloseIcon from '@mui/icons-material/Close'
import CompareIcon from '@mui/icons-material/Compare'
import LogoutIcon from '@mui/icons-material/Logout'
import TeleprompterIcon from '@mui/icons-material/Notes'
import Alert from '@mui/material/Alert'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { initializeApp } from 'firebase/app'
import {
  confirmPasswordReset,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'
import './App.css'

import LogoAsr from './assets/logo_voxist_asr.svg'

import { Avatar, Menu, MenuItem, Typography } from '@mui/material'
import Reset from 'routes/reset'
import SendReset from 'routes/sendReset'
import Teleprompter from 'routes/teleprompter'
import Form from './routes/form'
import Home from './routes/home'

const AuthContext = createContext(null)

const AlertContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const navigate = useNavigate()

  const { setAlert } = useAlert()
  auth.languageCode = 'fr'

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userOb) => {
      if (userOb) {
        // console.log(userOb)
        setUser(userOb)
        setAlert({ type: 'success', text: 'logged in' })
      } else {
        setUser(null)
      }
    })
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getToken = async () => {
    try {
      const token = await auth.currentUser.getIdToken()
      return token
    } catch (error) {
      setAlert({ type: 'error', text: 'no user' })
    }
  }
  const sendReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email)
      navigate('/')
    } catch (error) {
      setAlert({ type: 'error', text: 'issue with firebase' })
    }
  }
  const resetPass = async (oob, password) => {
    try {
      await confirmPasswordReset(auth, oob, password)
      navigate('/')
    } catch (error) {
      setAlert({ type: 'error', text: 'issue with firebase' })
    }
  }

  const signIn = async (email, password) => {
    try {
      return await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      setAlert({ type: 'error', text: 'bad login' })
    }
  }

  const signOut = async () => {
    await auth.signOut()
  }

  const value = { user, signIn, signOut, getToken, sendReset, resetPass }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
const RequireAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

const CheckAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.user) {
    return <Navigate to="/form" state={{ from: location }} replace />
  }

  return children
}

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null)
  const value = { alert, setAlert }

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}

const useAlert = () => useContext(AlertContext)

const Layout = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)

  const theme = useTheme()
  const { alert, setAlert } = useAlert()

  useEffect(() => {
    let timeout = null
    if (alert) {
      timeout = setTimeout(() => setAlert(null), 5000)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert])

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar
        style={{
          backgroundColor: 'transparent',
          top: 0,
          position: 'fixed',
          zIndex: 1,
          right: 0,
          left: 0,
          padding: 16,
          alignItems: 'center',
          justifyContent:
            location?.pathname !== '/home' ? 'space-between' : 'center',
          alignContent: 'center'
        }}
      >
        {location?.pathname !== '/' && (
          <Tooltip title="Home">
            <Link
              to="/"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent:
                  location?.pathname !== '/home' ? 'center' : 'center',
                alignContent: 'center',
                textDecoration: 'none'
              }}
            >
              <img src={LogoAsr} style={{ width: 54, height: 54 }} alt="icon" />
              <Typography
                variant="h6"
                style={{
                  color: 'white',
                  marginLeft: 10,
                  textDecoration: 'none'
                }}
              >
                Voxist AI
              </Typography>
            </Link>
          </Tooltip>
        )}
        {/* {!auth?.user && (
            <Tooltip title="reset">
              <Link
                to="/resetLink"
                style={{
                  flexGrow: 1,
                  textAlign: 'center',
                  textDecoration: 'none',
                  color: theme.palette.text.primary
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ flexGrow: 1, width: '100%', textAlign: 'center' }}
                >
                  reset
                </Typography>
              </Link>
            </Tooltip>
          )} */}

        {auth?.user && (
          <Box>
            <Tooltip title="User Menu">
              <IconButton onClick={handleMenuOpen}>
                <Avatar
                  sx={{ bgcolor: '#00CFF3', color: theme.palette.text.primary }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  navigate('/teleprompter')
                  handleMenuClose()
                }}
              >
                <Tooltip title="Teleprompter">
                  <IconButton>
                    <TeleprompterIcon />
                  </IconButton>
                </Tooltip>
                Teleprompter
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate('/form')
                  handleMenuClose()
                }}
              >
                <Tooltip title="Comparison">
                  <IconButton>
                    <CompareIcon />
                  </IconButton>
                </Tooltip>
                Comparison
              </MenuItem>
              <MenuItem
                onClick={() => {
                  auth.signOut(() => navigate('/'))
                  handleMenuClose()
                }}
              >
                <Tooltip title="Log Out">
                  <IconButton>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
                Log Out
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
      <Outlet />
      <Box sx={{ bottom: 0, position: 'fixed', right: 0 }}>
        <Fade in={Boolean(alert)} unmountOnExit>
          <Alert
            severity={alert?.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlert(null)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alert?.text}
          </Alert>
        </Fade>
      </Box>
    </AppBar>
  )
}

const firebaseConfig = {
  apiKey: 'AIzaSyCQdH1EsUt1VIYm5CyT8K2DhHbntFc7aw0',
  authDomain: 'asr-voxist.firebaseapp.com',
  databaseURL:
    'https://asr-voxist-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'asr-voxist',
  storageBucket: 'asr-voxist.appspot.com',
  messagingSenderId: '705011226493',
  appId: '1:705011226493:web:cfd71e448889ee055cde14',
  measurementId: 'G-K14CB3Q7DV'
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

export { useAlert, useAuth, useQuery }

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputBase: {
            styleOverrides: {
              input: {
                '&:-webkit-autofill': {
                  transitionDelay: '9999s',
                  transitionProperty: 'background-color, color'
                }
              }
            }
          },
          MuiFilledInput: {
            styleOverrides: {
              input: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: 'inherit',
                  WebkitTextFillColor: 'inherit',
                  caretColor: 'inherit',
                  borderRadius: 'inherit'
                }
              }
            }
          },
          MuiOutlinedInput: {
            styleOverrides: {
              input: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: 'inherit',
                  WebkitTextFillColor: 'inherit',
                  caretColor: 'inherit',
                  borderRadius: 'inherit'
                }
              }
            }
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                background: '#2E3B55'
              },
              colorDefault: {
                background: 'transparent'
              }
            }
          },
          MuiToolbar: {
            styleOverrides: {
              root: {
                background: 'transparent'
              }
            }
          }
        },
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          ...(prefersDarkMode
            ? {
                // palette values for light mode
                background: {
                  default: '#000000',
                  paper: '#121212'
                }
              }
            : {
                // palette values for dark mode

                background: {
                  default: '#ffffff',
                  paper: '#f8f8f8'
                }
              })
        }
      }),
    [prefersDarkMode]
  )
  return (
    <AlertProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <CheckAuth>
                    <Home />
                  </CheckAuth>
                }
              />
              <Route
                path="/resetLink"
                element={
                  <CheckAuth>
                    <SendReset />
                  </CheckAuth>
                }
              />
              <Route
                path="/reset"
                element={
                  <CheckAuth>
                    <Reset />
                  </CheckAuth>
                }
              />

              <Route
                path="/form"
                element={
                  <RequireAuth>
                    <Form />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="/teleprompter"
                element={
                  <RequireAuth>
                    <Teleprompter />
                  </RequireAuth>
                }
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </AlertProvider>
  )
}

export default App
