import { styled } from '@mui/material/styles'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import superagent from 'superagent'
import { useAlert, useAuth } from '../App'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import Tooltip from '@mui/material/Tooltip'
import { io } from 'socket.io-client'
import useStyles from '../styles/useStyles'
import Box from '@mui/material/Box'
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import frFlag from '../assets/195-france.png'
import gbFlag from '../assets/260-united-kingdom.png'
import deFlag from '../assets/162-germany.png'
import nlFlag from '../assets/237-netherlands.png'
import esFlag from '../assets/128-spain.png'
import itFlag from '../assets/013-italy.png'
import svFlag from '../assets/184-sweden.png'

import FileUploadIcon from '@mui/icons-material/FileUpload'
import MicIcon from '@mui/icons-material/Mic'
import SendIcon from '@mui/icons-material/Send'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import ResultBox from './ResultBox'
import lodash from 'lodash'
import useRecorder from './recorder'

const Input = styled('input')({
  display: 'none'
})

const Form = () => {
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    language,
    setLanguage,
    responseSocketGoogle,
    responseSocketMicrosoft,
    setResponseSocketGoogle,
    setResponseSocketMicrosoft,
    responseSocketVoxist,
    setResponseSocketVoxist,
    setAudioURL,
    isVoxist,
    setIsVoxist,
    voc,
    setVoc,
    model,
    setModel
  ] = useRecorder()
  const [socket, setSocket] = useState(null)
  const classes = useStyles()
  const { setAlert } = useAlert()

  const [loading, setLoading] = useState(false)
  const auth = useAuth()
  const [file, setFile] = useState(null)
  const [responseGoogle, setResponseGoogle] = useState(null)
  const [responseMicrosoft, setResponseMicrosoft] = useState(null)
  const [responseVoxist, setResponseVoxist] = useState(null)
  useEffect(() => {
    const initSocket = async () => {
      const token = await auth.getToken()
      let sock = null
      if (process.env.REACT_APP_DOCKER) {
        sock = io({
          auth: { token },
          transports: ['websocket']
        })
        sock.on('googleFileResponse', (arg) => {
          // console.log(arg)
          setResponseGoogle(arg)
        })
        sock.on('microsoftFileResponse', (arg) => {
          setResponseMicrosoft(arg)
          // console.log(arg)
        })
        sock.on('voxistFileResponse', (arg) => {
          setResponseVoxist(arg)
          // console.log(arg)
        })
        setSocket(sock)
      } else {
        sock = io('localhost:80', {
          auth: { token },
          transports: ['websocket']
        })
        sock.on('googleFileResponse', (arg) => {
          // console.log(arg)
          setResponseGoogle(arg)
        })
        sock.on('microsoftFileResponse', (arg) => {
          setResponseMicrosoft(arg)
          // console.log(arg)
        })
        sock.on('voxistFileResponse', (arg) => {
          setResponseVoxist(arg)
          // console.log(arg)
        })
        setSocket(sock)
      }
    }

    if (socket) {
      socket.on('connect', () => {
        // console.log(socket.id) // x8WIv7-mJelg7on_ALbx
      })

      socket.on('disconnect', () => {
        // console.log(socket.id) // undefined
      })
      socket.on('connect_error', async () => {
        try {
          const token = await auth.getToken()
          socket.auth = { token }
          socket.connect()
        } catch (error) {
          setAlert({ type: 'error', text: "can't get auth" })
        }
      })
    } else {
      initSocket().then()
    }
  })
  const toBase64 = () =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  const recordToB64 = async () => {
    const blob = await superagent.get(audioURL).responseType('blob')
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob.body)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handleCheckboxChange = (event) => {
    setIsVoxist(event.target.checked)
  }

  const send = async () => {
    setResponseSocketGoogle(null)
    setResponseSocketMicrosoft(null)
    setResponseSocketVoxist(null)
    URL.revokeObjectURL(audioURL)
    setAudioURL(null)
    setLoading(true)
    try {
      const [token, base64] = await Promise.all([auth.getToken(), toBase64()])
      try {
        const obj: {
          buffer: unknown
          language: string
          socket_id?: string
          isVoxist: boolean
          voc?: string[]
          model?: string
        } = {
          buffer: base64,
          language,
          isVoxist,
          voc: voc ? voc?.replace(' ', '').split(',') : null,
          model
        }
        if (socket) {
          obj.socket_id = socket.id
        }

        const response = await superagent
          .post(
            process.env.REACT_APP_DOCKER ? '/demo' : 'http://localhost:80/demo'
          )
          .auth(token, { type: 'bearer' })
          .send(obj)
        setResponseGoogle(response?.body?.google)
        setResponseMicrosoft(response?.body?.microsoft)
        setResponseVoxist(response?.body?.voxist)
        setLoading(false)
      } catch (error2) {
        setAlert({ type: 'error', text: "can't reach server" })
        setResponseGoogle(null)
        setResponseMicrosoft(null)
        setResponseVoxist(null)
        setLoading(false)
      }
    } catch (error) {
      setAlert({ type: 'error', text: "can't read file" })
      console.log(error)
      setResponseGoogle(null)
      setResponseMicrosoft(null)
      setResponseVoxist(null)
      setLoading(false)
    }
  }

  const sendRecord = async () => {
    setResponseSocketGoogle(null)
    setResponseSocketMicrosoft(null)
    setResponseSocketVoxist(null)

    setLoading(true)
    try {
      const [token, base64] = await Promise.all([
        auth.getToken(),
        recordToB64()
      ])
      try {
        const response = await superagent
          .post(
            process.env.REACT_APP_DOCKER
              ? '/demo/record'
              : 'http://localhost:80/demo/record'
          )
          .auth(token, { type: 'bearer' })
          .send({
            buffer: base64,
            language,
            isVoxist,
            voc: voc ? voc?.replace(' ', '').split(',') : null,
            model
          })
        setResponseGoogle(response?.body?.google)
        setResponseMicrosoft(response?.body?.microsoft)
        setResponseVoxist(response?.body?.voxist)
        setLoading(false)
      } catch (error2) {
        setAlert({ type: 'error', text: "can't reach server" })
        setResponseGoogle(null)
        setResponseMicrosoft(null)
        setResponseVoxist(null)
        setLoading(false)
      }
    } catch (error) {
      setAlert({ type: 'error', text: "can't read file" })
      console.log(error)
      setResponseGoogle(null)
      setResponseMicrosoft(null)
      setResponseVoxist(null)
      setLoading(false)
    }
  }

  const handleModelChange = (event) => {
    setModel(event.target.value)
  }

  const startRecord = () => {
    setResponseGoogle(null)
    setResponseMicrosoft(null)
    setResponseVoxist(null)
    setFile(null)
    startRecording()
  }

  const copyTextGoogle = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(
        JSON.stringify(responseGoogle || responseSocketGoogle || {})
      )
    }
  }
  const copyTextVoxist = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(
        JSON.stringify(responseVoxist || responseSocketVoxist || {})
      )
    }
  }

  const copyTextMicrosoft = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(
        JSON.stringify(responseMicrosoft || responseSocketMicrosoft || {})
      )
    }
  }

  const [fontSize, setFontSize] = useState(24)

  const onIncreaseFontSize = () => {
    if (fontSize > 10 && fontSize < 48) {
      setFontSize((prev) => prev + 2)
    }
  }

  const onDecreaseFontSize = () => {
    if (fontSize > 12 && fontSize < 50) {
      setFontSize((prev) => prev - 2)
    }
  }
  const getText = (response) => {
    if (response) {
      if (Array.isArray(response)) {
        const text = response?.map((r) => r.text)?.join(' ') || ''
        return (
          text
            .replace(
              /(ouvrez|ouvrer|ouvré|ouvrir|ouvre|ouvres) la (parenthèse|parenthese)/gimu,
              '('
            )
            .replace(
              /(fermer|fermez|fermé|ferme|fermes) la (parenthèse|parenthese)/gimu,
              ')'
            )
            .replace(
              /(ouvrez|ouvrer|ouvré|ouvrir|ouvre|ouvres) les (guillemets|guillemet|guillemais)/gimu,
              '«'
            )
            .replace(
              /(fermer|fermez|fermé|ferme|fermes) les (guillemets|guillemet|guillemais)/gimu,
              '»'
            )
            .replace(
              /a la ligne|à la ligne|retour ligne|retour à la ligne|retour a la ligne/gimu,
              '\n'
            )
            .replace(/points|point/gimu, '.')
            .replace(
              /deux points|2 point|2 points|deux point|2 \.|deux \./gimu,
              ':'
            )
            .replace(/:/gimu, ':\n')
            .replace(/virgule/gimu, ',')
            .replace(/point virgule|\.,|\.-,/gimu, ';')
            .replace(/\.,|\.-,|\. ,/gimu, ';')
            .replace(/poin;/gimu, ';')
            .replace(/(\.[a-z])|(\. [a-z])/gimu, (match) => match.toUpperCase())
            .replace(/(:[a-z])|(: [a-z])|(:\n[a-z])/gimu, (match) =>
              match.toUpperCase()
            )
            .replace(/\n\s*?\p{Ll}/gimu, (match) => match.toUpperCase())
            .replace(/\s+([.,])/gimu, '$1')
            .replace(/(:)/gimu, ' $1')
            .replace(/(;)/gimu, ' $1')
            // .replace(/\s+(»)/gimu, '$1')
            // .replace(/(«)\s+/gimu, '$1')
            .replace(/\s+(\))/gimu, '$1')
            .replace(/(\()\s+/gimu, '$1')
            .replace(/\./gimu, '.\n')
        )
      } else {
        return response?.text
      }
    } else {
      return ''
    }
  }
  const getConfidence = (response) => {
    if (Array.isArray(response)) {
      return lodash.sumBy(response, (r) => r.confidence) / response.length
    } else {
      return response.confidence
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignContent={
        responseGoogle ||
        responseMicrosoft ||
        responseVoxist ||
        responseSocketGoogle ||
        responseSocketMicrosoft ||
        responseSocketVoxist
          ? 'flex-start'
          : 'center'
      }
      className={classes.root}
    >
      <Grid container spacing={2} justifyContent="center">
        {/* Top Box */}
        <Grid item xs={12} sm={10} md={10}>
          <Box
            className={classes.settingContainer}
            sx={{
              maxWidth: { xs: 512, lg: '100%' },
              margin: '0 auto',
              padding: 2,
              gap: 2,
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              alignContent: 'center',
              justifyContent: 'space-between',
              alignItems: { xs: 'stretch', lg: 'center' }
            }}
          >
            <Box>
              <Typography variant="h6">Welcome to Voxist AI</Typography>
              <Typography variant="caption">
                Try our new system with an audio file or directly record
              </Typography>
            </Box>

            <Box>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                fullWidth
              >
                <MenuItem value="fr-FR">
                  <Avatar sx={{ width: 24, height: 24 }} src={frFlag} />
                  French
                </MenuItem>
                <MenuItem value="en-US">
                  <Avatar sx={{ width: 24, height: 24 }} src={gbFlag} />
                  English
                </MenuItem>
                <MenuItem value="de-DE">
                  <Avatar sx={{ width: 24, height: 24 }} src={deFlag} />
                  German
                </MenuItem>
                <MenuItem value="nl-NL">
                  <Avatar sx={{ width: 24, height: 24 }} src={nlFlag} />
                  Dutch
                </MenuItem>
                <MenuItem value="it-IT">
                  <Avatar sx={{ width: 24, height: 24 }} src={itFlag} />
                  Italian
                </MenuItem>
                <MenuItem value="es-ES">
                  <Avatar sx={{ width: 24, height: 24 }} src={esFlag} />
                  Spanish
                </MenuItem>
                <MenuItem value="sv-SE">
                  <Avatar sx={{ width: 24, height: 24 }} src={svFlag} />
                  Swedish
                </MenuItem>
              </Select>
            </Box>
            <Box sx={{ minWidth: 128 }}>
              <Select value={model} onChange={handleModelChange} fullWidth>
                <MenuItem value="slow">Voxist V2</MenuItem>
                <MenuItem value="fast">Voxist V1</MenuItem>
                <MenuItem value="medical">Voxist medical</MenuItem>
                <MenuItem value="banafo">Voxist V3</MenuItem>
              </Select>
            </Box>
            <Box sx={{ minWidth: 256 }}>
              <TextField
                aria-label="Voc separate words with trailing comma"
                placeholder="Voc separate words with trailing comma"
                value={voc}
                onChange={(e) => setVoc(e.target.value)}
                style={{ height: '100%' }}
                fullWidth
                variant="outlined"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                alignContent: 'center',
                alignItems: 'center',
                gap: 1
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isVoxist}
                    onChange={handleCheckboxChange}
                  />
                }
                labelPlacement="start"
                label="Only Voxist"
              />

              <Tooltip title="upload wav file">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="audio/*"
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => {
                      setFile(e.target.files[0])
                    }}
                  />
                  <Button
                    className={classes.buttonSquare}
                    variant="contained"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </Tooltip>

              <Tooltip title="record on your mic">
                <Button
                  className={classes.buttonSquare}
                  variant="contained"
                  onClick={!isRecording ? startRecord : stopRecording}
                  startIcon={!isRecording ? <MicIcon /> : <StopCircleIcon />}
                >
                  {isRecording ? 'stop' : 'record'}
                </Button>
              </Tooltip>
              <Tooltip title="send file to get result">
                <span>
                  <LoadingButton
                    className={classes.buttonSquare}
                    loading={loading}
                    // loadingPosition="start"
                    onClick={send}
                    disabled={!file || loading}
                    variant="contained"
                    startIcon={<SendIcon />}
                  >
                    send
                  </LoadingButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
          {audioURL && !isRecording && (
            <Box
              className={classes.setting2Container}
              sx={{
                maxWidth: { xs: 512, lg: '80%' },
                margin: '0 auto',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Grid item>
                <audio controls id="player" src={audioURL}></audio>
              </Grid>
              <Grid item>
                <Tooltip title="send record file to get result">
                  <span style={{ width: '100%' }}>
                    <LoadingButton
                      className={classes.buttonSquare}
                      loading={loading}
                      // loadingPosition="start"
                      onClick={sendRecord}
                      disabled={!audioURL || loading || isRecording}
                      variant="contained"
                      startIcon={<SendIcon />}
                    >
                      send record
                    </LoadingButton>
                  </span>
                </Tooltip>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
      {/*
       Result Box
       change the ResultBox component to display the result of the API calls
      */}
      <Grid
        container
        item
        xs={12}
        spacing={2}
        className={classes.paddingBox}
        justifyContent="center"
      >
        {responseGoogle && (
          <ResultBox
            onDecreaseFontSize={onDecreaseFontSize}
            onIncreaseFontSize={onIncreaseFontSize}
            copyTextAction={copyTextGoogle}
            fontSize={fontSize}
            confidence={getConfidence(responseGoogle)}
            duration={responseGoogle?.duration}
            text={getText(responseGoogle)}
            provider="Google"
            onlyVoxist={isVoxist}
          />
        )}

        {responseMicrosoft && (
          <ResultBox
            onDecreaseFontSize={onDecreaseFontSize}
            onIncreaseFontSize={onIncreaseFontSize}
            copyTextAction={copyTextMicrosoft}
            fontSize={fontSize}
            confidence={getConfidence(responseMicrosoft)}
            duration={responseMicrosoft?.duration}
            text={getText(responseMicrosoft)}
            provider="Microsoft"
            onlyVoxist={isVoxist}
          />
        )}

        {responseVoxist && (
          <ResultBox
            onDecreaseFontSize={onDecreaseFontSize}
            onIncreaseFontSize={onIncreaseFontSize}
            copyTextAction={copyTextVoxist}
            fontSize={fontSize}
            duration={responseVoxist?.duration}
            confidence={getConfidence(responseVoxist)}
            text={getText(responseVoxist)}
            provider="Voxist"
            onlyVoxist={isVoxist}
          />
        )}

        {responseSocketGoogle && (
          <ResultBox
            onDecreaseFontSize={onDecreaseFontSize}
            onIncreaseFontSize={onIncreaseFontSize}
            copyTextAction={copyTextGoogle}
            fontSize={fontSize}
            duration={responseSocketGoogle?.duration}
            confidence={getConfidence(responseSocketGoogle)}
            text={getText(responseSocketGoogle)}
            provider="Google"
            onlyVoxist={isVoxist}
          />
        )}
        {responseSocketMicrosoft && (
          <ResultBox
            onDecreaseFontSize={onDecreaseFontSize}
            onIncreaseFontSize={onIncreaseFontSize}
            copyTextAction={copyTextMicrosoft}
            fontSize={fontSize}
            duration={responseSocketMicrosoft?.duration}
            confidence={getConfidence(responseSocketMicrosoft)}
            text={getText(responseSocketMicrosoft)}
            provider="Microsoft"
            onlyVoxist={isVoxist}
          />
        )}

        {responseSocketVoxist && (
          <ResultBox
            onDecreaseFontSize={onDecreaseFontSize}
            onIncreaseFontSize={onIncreaseFontSize}
            copyTextAction={copyTextVoxist}
            fontSize={fontSize}
            duration={responseSocketVoxist?.duration}
            confidence={getConfidence(responseSocketVoxist)}
            text={getText(responseSocketVoxist)}
            provider="Voxist"
            onlyVoxist={isVoxist}
          />
        )}
      </Grid>

      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            item
            style={{
              maxWidth: '512px',
              backdropFilter: 'blur(4px)'
            }}
            className={classes.gridContainer}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                className={classes.paddingBox}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Box
                        fontWeight="fontWeightBold"
                        fontSize="h6.fontSize"
                        textAlign="center"
                      >
                        {'Welcome to Voxist AI'}
                      </Box>
                      <Box fontSize="caption.fontSize" textAlign="center">
                        {
                          'Try our new system with an audio file or directly record'
                        }
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() =>
                        setLanguage(language === 'en-US' ? 'fr-FR' : 'en-US')
                      }
                      className={classes.buttonUnselected}
                      size="small"
                      variant="outlined"
                      startIcon={
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                          src={language === 'en-US' ? gbFlag : frFlag}
                        />
                      }
                    >
                      {language === 'en-US' ? 'English' : 'French'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {auth?.user?.email?.includes('@voxist.com') && (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  className={classes.paddingBox}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="settings"
                        id="settings"
                      >
                        <Box fontSize="caption.fontSize" textAlign="left">
                          {'settings'}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isVoxist}
                              onChange={(e) => setIsVoxist(e.target.checked)}
                            />
                          }
                          label="only Voxist"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={punc}
                              onChange={(e) => setPunc(e.target.checked)}
                            />
                          }
                          label="punctuation"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={fast}
                              onChange={(e) => setFast(e.target.checked)}
                            />
                          }
                          label="fast model"
                        />
                        <TextareaAutosize
                          aria-label="Voc separate words with trailing comma"
                          placeholder="Voc separate words with trailing comma"
                          style={{ width: 200 }}
                          value={voc}
                          onChange={(e) => setVoc(e.target.value)}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                className={classes.paddingBox}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box fontSize="caption.fontSize" textAlign="left">
                    {'- Choose how you want to test it'}
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Tooltip title="upload wav file">
                    <label
                      style={{ width: '100%' }}
                      htmlFor="contained-button-file"
                    >
                      <Input
                        accept="audio/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          setFile(e.target.files[0])
                        }}
                      />
                      <Button
                        className={classes.buttonSquare}
                        fullWidth
                        variant="contained"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Tooltip title="record on your mic">
                    <Button
                      className={classes.buttonSquare}
                      fullWidth
                      variant="contained"
                      onClick={!isRecording ? startRecord : stopRecording}
                      startIcon={
                        !isRecording ? <MicIcon /> : <StopCircleIcon />
                      }
                    >
                      {isRecording ? 'stop' : 'record'}
                    </Button>
                  </Tooltip>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Tooltip title="send file to get result">
                    <span style={{ width: '100%' }}>
                      <LoadingButton
                        fullWidth
                        className={classes.buttonSquare}
                        loading={loading}
                        // loadingPosition="start"
                        onClick={send}
                        disabled={!file || loading}
                        variant="contained"
                        startIcon={<SendIcon />}
                      >
                        send
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </Grid>
                {audioURL && !isRecording && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <audio
                      style={{ width: '100%' }}
                      controls
                      id="player"
                      src={audioURL}
                    ></audio>
                    <Tooltip title="send record file to get result">
                      <span style={{ width: '100%' }}>
                        <LoadingButton
                          fullWidth
                          className={classes.buttonSquare}
                          loading={loading}
                          // loadingPosition="start"
                          onClick={sendRecord}
                          disabled={!audioURL || loading || isRecording}
                          variant="contained"
                          startIcon={<SendIcon />}
                        >
                          send record
                        </LoadingButton>
                      </span>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  )
}

export default Form
