import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { useAuth } from '../App'
import useStyles from '../styles/useStyles'

import EmailIcon from '@mui/icons-material/Email'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { InputAdornment, Tooltip, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import LogoAsr from '../assets/logo_voxist_asr.svg'

const Home = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const auth = useAuth()
  const classes = useStyles()
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.rootlog}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            item
            style={{
              maxWidth: '512px',
              backdropFilter: 'blur(4px)'
            }}
            className={classes.gridContainer}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                className={classes.paddingBox}
              >
                <Grid item className={classes.titleBox}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <img
                      src={LogoAsr}
                      style={{ width: 128, height: 128 }}
                      alt="icon"
                    />

                    <Box fontSize="caption.fontSize" textAlign="center">
                      {'Welcome to Voxist AI'}
                    </Box>

                    <Box
                      fontWeight="fontWeightBold"
                      fontSize="h6.fontSize"
                      textAlign="center"
                    >
                      {'Sign in to you demo account'}
                    </Box>
                  </Grid>
                </Grid>
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    className={classes.textField}
                    name="mail"
                    label="Email"
                    type="email"
                    value={email}
                    InputProps={{
                      className: classes.inputField,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="john.doe@gmail.com"
                  />
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="password"
                    label="Password"
                    type="password"
                    className={classes.textField}
                    InputProps={{
                      className: classes.inputField,
                      disableUnderline: true,

                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOpenIcon />
                        </InputAdornment>
                      )
                    }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="********"
                  />
                  <Grid
                    item
                    textAlign="right"
                    className={classes.marginVerticalOnly}
                  >
                    <Tooltip title="reset">
                      <Link className={classes.forgotPassword} to="/resetLink">
                        <Typography variant="caption" align="right">
                          {'Forgot password ?'}
                        </Typography>
                      </Link>
                    </Tooltip>
                  </Grid>
                  <Grid item className={classes.marginVerticalOnly}>
                    <Button
                      className={classes.buttonSquare}
                      fullWidth
                      size="large"
                      onClick={() => auth?.signIn(email, password)}
                      variant="contained"
                    >
                      Log in
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.paddingBox}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  fontWeight="fontWeightBold"
                  fontSize="caption.fontSize"
                  textAlign="center"
                >
                  {'© 2024 VOXIST, all right reserved.'}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Home
