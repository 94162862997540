import { useState } from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useAuth } from '../App'
import useStyles from '../styles/useStyles'
import EmailIcon from '@mui/icons-material/Email'
import { InputAdornment } from '@mui/material'

const SendReset = () => {
  const [email, setEmail] = useState('')
  const auth = useAuth()
  const classes = useStyles()
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.rootlog}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            item
            style={{
              maxWidth: '512px',
              backdropFilter: 'blur(4px)'
            }}
            className={classes.gridContainer}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                className={classes.paddingBox}
              >
                <Grid item className={classes.titleBox}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box
                      fontWeight="fontWeightBold"
                      fontSize="h6.fontSize"
                      textAlign="left"
                    >
                      {'Forgot password ?'}
                    </Box>
                    <Box fontSize="caption.fontSize" textAlign="left">
                      {
                        "Please enter your email address, We'll send you instructions to help reset your password"
                      }
                    </Box>
                  </Grid>
                </Grid>
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    fullWidth
                    className={classes.textField}
                    name="mail"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="john.doe@gmail.com"
                    InputProps={{
                      className: classes.inputField,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      )
                    }}
                  />

                  <Grid item className={classes.marginVerticalOnly}>
                    <Button
                      className={classes.buttonSquare}
                      fullWidth
                      onClick={() => auth?.sendReset(email)}
                      variant="contained"
                      size="large"
                    >
                      Send reset instructions
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default SendReset
