import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import TextDecreaseIcon from '@mui/icons-material/TextDecrease'
import TextIncreaseIcon from '@mui/icons-material/TextIncrease'
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@mui/material'
import React from 'react'

// replace with your actual styles
import useStyles from '../styles/useStyles'

interface ResultBoxProps {
  onDecreaseFontSize: () => void
  onIncreaseFontSize: () => void
  copyTextAction: () => void
  fontSize: number
  confidence?: number
  duration: number
  text: string
  provider: string
  onlyVoxist?: boolean
}

const ResultBox: React.FC<ResultBoxProps> = ({
  onDecreaseFontSize,
  onIncreaseFontSize,
  copyTextAction,
  fontSize,
  confidence,
  duration,
  text,
  provider,
  onlyVoxist
}) => {
  const classes = useStyles()

  return (
    <Grid
      item
      xs={12}
      sm={onlyVoxist ? 10 : 6}
      md={onlyVoxist ? 10 : 3}
      container
      justifyContent="center"
    >
      <Box
        sx={{
          width: '100%'
        }}
        className={classes.resultContainer}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.paddingBox}
        >
          <Grid item xs={6}>
            <Typography variant="h6" fontWeight="bold">
              {`${provider} result:`}
            </Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Tooltip title="Decrease Text">
              <IconButton
                aria-label="decrease text"
                onClick={onDecreaseFontSize}
              >
                <TextDecreaseIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Insrease Text">
              <IconButton
                aria-label="increase text"
                onClick={onIncreaseFontSize}
              >
                <TextIncreaseIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="copy result object to your clipboard">
              <IconButton aria-label="copy" onClick={copyTextAction}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignContent="center"
          className={classes.paddingBox}
        >
          <Box
            fontWeight="fontWeightBold"
            fontSize="body1.fontSize"
            textAlign="center"
          >
            {'Duration : '}
          </Box>
          <Box
            fontWeight="fontWeightBold"
            fontSize="caption.fontSize"
            textAlign="center"
          >
            {`${(duration || 0) / 1000} sec`}
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.paddingBox}
        >
          {confidence !== undefined && (
            <>
              <Box
                fontWeight="fontWeightBold"
                fontSize="body1.fontSize"
                textAlign="center"
              >
                {'Confidence : '}
              </Box>

              <LinearProgress
                style={{ width: '70%' }}
                variant="determinate"
                className={classes.progressBar}
                value={parseInt((confidence * 100).toString(), 10)}
              />

              <Box
                fontWeight="fontWeightBold"
                fontSize="caption.fontSize"
                textAlign="center"
              >
                {`${parseInt((confidence * 100).toString(), 10)}%`}
              </Box>
            </>
          )}
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          item
          container
          className={classes.transcriptionBox}
        >
          <Box
            fontWeight="fontWeightBold"
            style={{ fontSize: fontSize }}
            textAlign="left"
          >
            {text}
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}

export default ResultBox
