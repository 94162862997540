import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import useStyles from '../styles/useStyles'
import useRecorder from './recorder'
import { useAuth } from 'App'
import { io } from 'socket.io-client'

const Teleprompter = () => {
  const [
    ,
    isRecording,
    startRecording,
    stopRecording,
    ,
    setLanguage,
    ,
    ,
    ,
    ,
    responseSocketVoxist,
    setResponseSocketVoxist,
    ,
    ,
    ,
    ,
    ,
    ,
    setModel
  ] = useRecorder()
  const theme = useTheme()
  const classes = useStyles()
  const teleprompterRef = useRef(null)
  const [socket, setSocket] = useState(null)
  const auth = useAuth()

  useEffect(() => {
    setModel('banofi')
    setLanguage('fr-FR')
    const initSocket = async () => {
      const token = await auth.getToken()
      let sock = null
      if (process.env.REACT_APP_DOCKER) {
        sock = io({
          auth: { token },
          transports: ['websocket']
        })

        setSocket(sock)
      } else {
        sock = io('localhost:80', {
          auth: { token },
          transports: ['websocket']
        })
        setSocket(sock)
      }
    }

    if (socket) {
      socket.on('connect', () => {
        // console.log(socket.id) // x8WIv7-mJelg7on_ALbx
      })

      socket.on('disconnect', () => {
        // console.log(socket.id) // undefined
      })
      socket.on('connect_error', async () => {
        try {
          const token = await auth.getToken()
          socket.auth = { token }
          socket.connect()
        } catch (error) {}
      })
    } else {
      initSocket().then()
    }
  })

  useEffect(() => {
    if (teleprompterRef.current) {
      teleprompterRef.current.scrollTop = teleprompterRef.current.scrollHeight
    }
  }, [responseSocketVoxist])

  const handleStart = () => {
    setResponseSocketVoxist(null)
    startRecording()
  }

  const handleStop = () => {
    stopRecording()
  }
  const getText = (response): string => {
    if (response && response?.length) {
      if (Array.isArray(response)) {
        const text = response.map((r) => r.text).join(' ')
        return (
          text
            .replace(
              /(ouvrez|ouvrer|ouvré|ouvrir|ouvre|ouvres) la (parenthèse|parenthese)/gimu,
              '('
            )
            .replace(
              /(fermer|fermez|fermé|ferme|fermes) la (parenthèse|parenthese)/gimu,
              ')'
            )
            .replace(
              /(ouvrez|ouvrer|ouvré|ouvrir|ouvre|ouvres) les (guillemets|guillemet|guillemais)/gimu,
              '«'
            )
            .replace(
              /(fermer|fermez|fermé|ferme|fermes) les (guillemets|guillemet|guillemais)/gimu,
              '»'
            )
            .replace(
              /a la ligne|à la ligne|retour ligne|retour à la ligne|retour a la ligne/gimu,
              '\n'
            )
            .replace(/points|point/gimu, '.')
            .replace(
              /deux points|2 point|2 points|deux point|2 \.|deux \./gimu,
              ':'
            )
            .replace(/:/gimu, ':\n')
            .replace(/virgule/gimu, ',')
            .replace(/point virgule|\.,|\.-,/gimu, ';')
            .replace(/\.,|\.-,|\. ,/gimu, ';')
            .replace(/poin;/gimu, ';')
            .replace(/(\.[a-z])|(\. [a-z])/gimu, (match) => match.toUpperCase())
            .replace(/(:[a-z])|(: [a-z])|(:\n[a-z])/gimu, (match) =>
              match.toUpperCase()
            )
            .replace(/\n\s*?\p{Ll}/gimu, (match) => match.toUpperCase())
            .replace(/\s+([.,])/gimu, '$1')
            .replace(/(:)/gimu, ' $1')
            .replace(/(;)/gimu, ' $1')
            // .replace(/\s+(»)/gimu, '$1')
            // .replace(/(«)\s+/gimu, '$1')
            .replace(/\s+(\))/gimu, '$1')
            .replace(/(\()\s+/gimu, '$1')
            .replace(/\./gimu, '.\n')
        )
      } else {
        return response.text
      }
    } else {
      return ''
    }
  }
  const text = getText(responseSocketVoxist)
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignContent={'flex-start'}
      className={classes.root}
    >
      <Box
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',

          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          overflow: 'hidden'
        }}
      >
        <Box
          style={{
            width: '80%',
            height: '80%',
            overflow: 'scroll',
            padding: '20px',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '20px',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
            position: 'relative'
          }}
          ref={teleprompterRef}
        >
          <Typography
            variant="h4"
            style={{
              color: theme.palette.text.primary,
              fontSize: '3rem',
              whiteSpace: 'pre-line',
              textAlign: 'left',
              overflow: 'hidden',
              position: 'absolute',
              animation: isRecording
                ? 'typing 5s steps(' +
                  (text.length + 20) +
                  ', end) infinite, blink-caret 0.5s step-end infinite'
                : 'none' // Apply typing and blinking cursor animations only when playing
            }}
          >
            {text}
            <span
              style={{
                borderRight: '2px solid',
                animation: isRecording
                  ? 'blink-caret 0.5s step-end infinite'
                  : 'none'
              }}
            ></span>
          </Typography>
        </Box>

        {!isRecording && (
          <Box style={{ marginTop: '20px' }}>
            <Button
              variant="contained"
              onClick={handleStart}
              startIcon={<PlayCircleOutlineIcon />}
              style={{
                marginRight: '10px',
                borderRadius: '20px',
                backgroundColor: theme.palette.text.primary
              }}
            >
              Start
            </Button>
          </Box>
        )}
        {isRecording && (
          <Box style={{ marginTop: '20px' }}>
            {/* <Button
              variant="contained"
              onClick={handlePause}
              startIcon={<PauseCircleOutlineIcon />}
              style={{
                marginRight: '10px',
                borderRadius: '20px',
                backgroundColor: theme.palette.text.primary
              }}
            >
              Pause
            </Button> */}
            <Button
              variant="contained"
              onClick={handleStop}
              startIcon={<StopCircleOutlinedIcon />}
              style={{
                borderRadius: '20px',
                backgroundColor: theme.palette.text.primary
              }}
            >
              Stop
            </Button>
          </Box>
        )}

        <style>
          {`
          @keyframes blink-caret {
            from, to {
              border-color: transparent;
            }
            50% {
              border-color: ${theme.palette.text.primary};
            }
          }
        `}
        </style>
      </Box>
    </Grid>
  )
}

export default Teleprompter
